.journey-container {
    grid-template-rows: repeat(2, 1fr);
    width: auto;
    height: auto;
}

.journey-list-container {
    display: grid;
    background-color: white;
    grid-template-columns: repeat(4, 1fr);
    width: auto;
    height: auto;
    margin: 25px;
    gap: 25px;
    grid-auto-rows: minmax(5px, auto);
}

.journey-text-container {
    border-color: #71b1e1;
    border-style: dashed;
    border-width: 20px;
    margin: 50px;
    border: 5px;
}

.journey-item {
    border-style: thick solid;
    border-color: black;
    border: 10px solid #71b1e1;
    border-radius: 30px;
    box-shadow: 5px 5px 15px grey;
    position: relative;
}

.journey-title {
    right: 0;
    font-size: 75px;
    text-align: center;
    color: #71b1e1;
    font-weight: bold;
}

.journey-content {
    text-align: center;
    font-size: 25px;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif';
    margin-right: 25px;
    margin-left: 25px;
}


.journey-list {
    margin-top: 5px;
    margin-left: 25px;
    margin-right: 25px;
}

.timeline-h3 {
    font-size: 34px;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif';

}

.timeline-h4 {
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif';
    color:black

}

@media screen and (min-width: 770px) and (max-width: 1272px) {
    
}
  
  /* Styles for screens up to 600 pixels wide */
@media screen and (min-width: 481px) and (max-width: 769px) {
    
}
    
  /* Styles for screens up to 480 pixels wide */
@media screen and (max-width: 480px) {
    .journey-title {
        font-size: 50px;
    }

    .timeline-h3 {
        font-size: 25px;
    }

    .timeline-h4 {
        font-size: 18px;
    }
}