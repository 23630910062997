.growth-container {
    margin-top: 100px;
    display: grid;
    background-color:  #71b1e1;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
}

.line-container {
    background-color: #71b1e1;
    width: auto;
    height: 5px;
    margin: 25px;
}

.growth-text-container {
    border-color: #71b1e1;
    border-style: dashed;
    border-width: 20px;
    margin: 25px;
    border: 5px;
}

.growth-title {
    right: 0;
    font-size: 75px;
    text-align: center;
    margin: 20px;
    color: white;
    font-weight: bold;
}

.growth-content {
    display: grid;
    /* background-color: white; */
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    grid-auto-rows: minmax(250, auto);
}

.growth-content-container {
    border-right: 5px;
    border-color: #71b1e1;
    border-style: solid;
    background-color: #71b1e1;
    border-radius: 20px;
}

.growth-sub-title {
    margin-top: 30px;
    right: 0;
    font-size: 35px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.growth-content-views {
    margin: 30px 60px 60px 60px;
    justify-items: center;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    gap: 5px;
}
