.nav {
    display: grid;
    gap: 10px;
    grid-auto-rows: minmax(auto, auto);
    grid-gap: 30px;
    grid-auto-flow: column;
    width: auto;
    grid-template-columns: 100px ;
    justify-content: end;
}

.nav_header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(80px, auto);
    box-shadow: 0px -1px 10px grey;
    position: relative;
}

.logo_img {
    margin: 25px 10px 25px 40px;
    height: auto;
    width: 130px;
    background-size: 100% 100%;
}

.nav_button {
    /* font-family: 'Rock Jack Writing'; */
    margin: 10px 25px 10px 25px;
    display: grid;
    width: 100px;
    font-size: 30px;
    height: auto;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    color: #3374B9;
    font-weight: bold;
}

@media screen and (min-width: 770px) and (max-width: 1272px) {
  .nav_button {
    font-size: 30px;
  }

  .logo_img {
    
    width: 120px;
  }
}

/* Styles for screens up to 600 pixels wide */
@media screen and (min-width: 481px) and (max-width: 769px) {
  
  .nav_button {
    font-size: 25px;
  }

  .logo_img {
    width: 100px;
  }
}
  
/* Styles for screens up to 480 pixels wide */
@media screen and (max-width: 480px) {
   .nav {
    grid-template-columns: 30px ;  
  } 

  .nav_button {
    font-size: 16px;
    margin: 0px;
  }

  .logo_img {
    margin: 25px;
    width: 100px;
  }

}
  