* {
  margin: 0;
  padding: 0;
  font-family: 'Rock Jack Writing';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: 'Rock Jack Writing';
  src: local('Rock Jack Writing'),
       url('../../fonts/Rock\ Jack\ Writing.woff') format('woff');
       /* url('./fonts/Rock Jack Writing.woff2') format('woff2'); */
};

@font-face {
  font-family: 'Qaz';
  src: local('Qaz'),
       url('../../fonts/Qaz.ttf') format('truetype');
       /* url('./fonts/Rock Jack Writing.woff2') format('woff2'); */
};

@font-face {
  font-family: 'QazItalic';
  src: local('QazItalic'),
       url('../../fonts/QazItalic.ttf') format('truetype');
       /* url('./fonts/Rock Jack Writing.woff2') format('woff2'); */
};

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
