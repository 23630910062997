.bio-container {
    display: grid;
    background-color: white;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(auto, auto);
}

.bio-text-container {
    background-color: #71b1e1;
    margin: 5%;
    border: 5px;
    border-color: black;
    vertical-align: bottom;
}

.bio-greetings {
    right: 0;
    font-size: 100px;
    text-align: right;
    margin-top: 40%;
    margin-right: 5%;
    margin-left: 5%;
    color: white;
    font-weight: bold;
}

.bio-name {
    right: 0;
    font-size: 50px;
    text-align: right;
    margin-right: 5%;
    margin-left: 5%;
    color: white;

}

.bio-position {
    right: 0;
    font-size: 35px;
    text-align: right;
    margin-right: 5%;
    margin-left: 5%;
    color: white;

}

.bio-img-container {
    width: auto;
    height: auto;
}


@media screen and (min-width: 770px) and (max-width: 1272px) {

    .bio-greetings {
        margin-top: 40%;
        font-size: 50px;
    }

    .bio-name {
        font-size: 30px;
        margin-right: 5%;
        margin-left: 6%;
    
    }

    .bio-position {
        font-size: 20px;
        margin-right: 5%;
        margin-left: 5%;
    
    }
}
  
/* Styles for screens up to 600 pixels wide */
@media screen and (min-width: 481px) and (max-width: 769px) {
    .bio-greetings {
        margin-top: 40%;
        font-size: 50px;
    }

    .bio-name {
        font-size: 20px;
        margin-right: 5%;
        margin-left: 5%;
    
    }

    .bio-position {
        font-size: 16px;
        margin-right: 5%;
        margin-left: 5%;
    
    }

}
    
/* Styles for screens up to 480 pixels wide */
@media screen and (max-width: 480px) {
    .bio-container {
        grid-template-rows: repeat(2, 1fr) ; 
        grid-template-columns: none ;
    }

    .bio-greetings {
        margin-top: 40%;
        font-size: 50px;
    }

    .bio-name {
        font-size: 20px;
        margin-right: 5%;
        margin-left: 5%;
    
    }

    .bio-position {
        font-size: 16px;
        margin-right: 5%;
        margin-left: 5%;
    
    }
}