.aboutme-container {
    display: grid;
    background-color: white;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
}

.line-container {
    background-color: #71b1e1;
    width: auto;
    height: 5px;
    margin: 25px;
}

.aboutme-text-container {
    border-color: #71b1e1;
    border-style: dashed;
    border-width: 20px;
    border: 5px;
}

.aboutme-title {
    right: 0;
    font-size: 75px;
    text-align: center;
    /* margin: 10px; */
    color: #71b1e1;
    font-weight: bold;
}

.aboutme-content {
    text-align: center;
    font-size: 25px;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif';
    margin-right: 25px;
    margin-left: 25px;
}

@media screen and (min-width: 770px) and (max-width: 1272px) {
  .aboutme-title {
    font-size: 50px;
    margin: 10px;
  }
  
  .aboutme-content {
    font-size: 22px;
  }
}

/* Styles for screens up to 600 pixels wide */
@media screen and (min-width: 481px) and (max-width: 769px) {
  .aboutme-title {
    font-size: 35px;
    margin: 10px;
  }
  .aboutme-content {
    font-size: 18px;
  }
}
  
/* Styles for screens up to 480 pixels wide */
@media screen and (max-width: 480px) {
  .aboutme-title {
    font-size: 50px;
    margin: 10px;
  }

  .aboutme-content {
    font-size: 14px;
  }
}
  
  
