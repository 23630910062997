

.language-title-h3 {
    right: 0;
    font-size: 30px;
    text-align: center;
    color: #71b1e1;
    font-weight: bold;
}

.language-p {
    right: 0;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.language-image {
    margin: 10px;
    height: auto;
    width: 75px;
    background-size: 100% 100%;
}
